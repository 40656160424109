import { combineReducers } from 'redux'
import actionsReducer from "../reducers/actions";
import reportsReducer from "../reducers/reports";
import toastsReducer from "../reducers/toasts";
import screenReducer from "../reducers/screen";
import userReducer from "../reducers/user";
import vouchersReducer from "../reducers/vouchers";
import mgmReducer from "../reducers/mgm";

export default (history) => combineReducers({
  actions: actionsReducer,
  reports: reportsReducer,
  toasts: toastsReducer,
  screen: screenReducer,
  user: userReducer,
  vouchers: vouchersReducer,
  mgm: mgmReducer
})