import _ from 'lodash'
import {
  GET_BATCHES_SUCCESS,
  GET_VOUCHERS_REQUEST,
  GET_VOUCHERS_SUCCESS,
  SELECT_BATCH
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case GET_BATCHES_SUCCESS:
      return {
        ...state,
        batches: action.payload
      }
    case GET_VOUCHERS_REQUEST:
      return {
        ...state,
        vouchers: []
      }
    case GET_VOUCHERS_SUCCESS:
      return {
        ...state,
        vouchers: action.payload
      }
    case SELECT_BATCH:
      return {
        ...state,
        batchID: action.payload
      }
    default:
      return state;
  }
}