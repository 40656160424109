import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { mgmJoin, mgmValidate, mgmClear, mgmShowForm, mgmInvite, mgmUpdate, setMobileOptIn, setCommercialOptIn, setSocialOptIn, setHypercareOptIn } from '../actions/mgm'
import BaseComponent from "../components/Helpers";
import { NavLink } from 'react-router-dom';
import _ from 'lodash'
import moment from 'moment';
import Button from './Button';

class ReportsPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      userEmail: '',
      emailError: false,
      smsConsent: false,
      phoneConsent: false,
      socialConsent: false,
      marketingConsent: false,
      consentError: false,
      status: false
    };
  }

  componentDidMount() {
    //this.props.mgmShowForm();
    //this.clear();
    this.props.mgmJoin()
    this.setState({status: this.checkStatus()})
  }

  checkStatus() {
    let status = false;
    this.props.actions.list.forEach(action => {
      if ( action.status.user.status === 'started') {
        status = true;
      }
    });

    //console.log(status)

    return status;
  }

  checkUser() {
    if (this.emailValid()) {
      this.props.mgmValidate(this.state.userEmail)
    }
  }

  emailValid() {
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.userEmail )) {
      this.setState({emailError: true})
      return false;
    } else {
      this.setState({emailError: false})
      return true;
    }
  }

  checkConsent() {
    if (this.props.mgm.consents.newsletterCommercialAuthorization) {
      this.setState({consentError: false});
      return true;
    } else {
      this.setState({ consentError: true});
      return false;
    }
  }

  clear() {
    this.props.mgmClear()
    this.setState({userEmail: ''})
  }

  content() {
    switch(this.props.mgm.status) {
      case null:
        if (this.state.status) { 
          return (
            <div className="mgm-user-add">
              <h2>Dodaj użytkownika do MGM</h2>
              <p>Sprawdź status użytkownika w SalesForce</p>
              <input type="email" value={this.state.userEmail} onChange={(e)=>{ this.setState({ userEmail: e.target.value})}}/>
              {this.state.emailError ? <p>Nieprawidłowy adres email</p> : null}
              <div className="center">
                <Button className="btn" action={ () => { this.checkUser() } }>Sprawdź</Button>
              </div>
            </div>
          )
        } else {
          return <div></div>
        }
      case 'opt-in-request':
        return (
          <div className="mgm-modal-box">
            <h3>Podany użytkownik nie wyraził wszystkich zgód marketingowych</h3>
            <p>Poproś użytkownika, by potwierdził wyrażenie zgód marketingowych.</p>
            <div className="buttons">
              <Button className="btn white" action={ ()=>{ this.clear() }} >Anuluj</Button>
              <Button className="btn" action={ ()=>{ this.props.mgmShowForm() }} >Potwierdź</Button>
            </div>
          </div>
        )
      case 'opt-in-form':
        let checkboxError = '';
        if (this.state.consentError) {
          checkboxError = 'field-error'
        }
        return (
          <div className="mgm-modal-box">
            <h3>Zgody marketingowe</h3>
            {/* Patvirtinu, kad esu vyresnis nei 18 metų */}
            <p className="form-small">Sutinku, kad BAT LT ir MINERALINIAI VANDENYS, kaip bendri duomenų valdytojai, mano pateiktus asmens duomenis tvarkytų tiesioginės rinkodaros tikslais, įskaitant mano pasirinkimų analizę (profiliavimas), informacijos apie BAT grupės siūlomus produktus, paslaugas, konkursus ir kitas BAT grupės iniciatyvas pateikimą man:</p>

            <div className={"checkbox-item "+checkboxError}>
              <input type="checkbox" id="sms_consent" name="sms_consent" checked={ this.props.mgm.consents.newsletterCommercialAuthorization } onChange={ (e) => { this.props.setCommercialOptIn(e.target.checked) } }/>
              <label htmlFor="sms_consent">
              elektroninėmis ryšio priemonėmis, t. y. el. paštu ir SMS žinutėmis
              </label>
            </div>
            <div className={"checkbox-item"}>
              <input type="checkbox" id="phone_consent" name="phone_consent" checked={ this.props.mgm.consents.mobileOptIn } onChange={ (e) => { this.props.setMobileOptIn(e.target.checked) } }/>
              <label htmlFor="phone_consent">
              telefonu, įskaitant balso skambučius
              </label>
            </div>
            <div className={"checkbox-item"}>
              <input type="checkbox" id="social_consent" name="social_consent" checked={ this.props.mgm.consents.socialMediaOptIn } onChange={ (e) => { this.props.setSocialOptIn(e.target.checked) } }/>
              <label htmlFor="social_consent">
              socialinių tinklų (pvz., „Facebook“, „Instagram“ ir pan.) reklamos skiltyse
              </label>
            </div>
            <div className="checkbox-item">
              <input type="checkbox" id="marketing_consent" name="marketing_consent" checked={ this.props.mgm.consents.hypercareOptIn } onChange={ (e) => { this.props.setHypercareOptIn(e.target.checked) } }/>
              <label htmlFor="marketing_consent">
              Sutinku, kad BAT LT ir MINERALINIAI VANDENYS, kaip bendri duomenų valdytojai, tvarkytų mano asmens duomenis, pateiktus apklausose apie BAT grupės siūlomų produktų naudojimą ir su klientų aptarnavimo programa susijusiose klientų pasitenkinimo apklausose, atliekamose telefonu ir kitomis elektroninėmis ryšio priemonėmis, įskaitant BAT ir MINERALINIAI VANDENYS interneto svetaines, kurių registruotas naudotojas esu.
              </label>
            </div>

            <p className="form-small"><strong>*Zgoda wymagana</strong></p>

            <p className="form-small">
            Dėmesio: Jūs bet kada galite atšaukti ar pakeisti savo sutikimą. Asmens duomenys tiesioginės rinkodaros ir apklausų vykdymo tikslais tvarkomi kaip nurodyta privatumo politikoje. 
            </p >

            <p className="form-small">
              Współadministratorami danych osobowych,
              o których mowa w powyższym formularzu
              są British American Tobacco Polska Trading
              sp. z o.o. z siedzibą w Warszawie (BAT)
              oraz CHIC spółka z ograniczoną odpowiedzialnością sp. k. z siedzibą
              w Ostrzeszowie (CHIC). Twoje dane będą przewarzane wyłącznie w tych celach,
              na które wyraziłeś/aś swoją zgodę powyżej.
            </p>

            <p className="form-small">
              Możesz się z nami skontaktować pisząc na adres poczty elektronicznej daneosobowe@bat.com.pl lub listownie na adres British American Tobacco Polska Trading Spółka z o.o., ul. Krakowiaków 48, 02-255 Warszawa.
            </p>

            <p className="form-small">
              W związku z podaniem danych osobowych przysługuje Ci prawo do uzyskania dostępu do treści Twoich danych, prawo ich poprawiania, prawo do ich przenoszenia, prawo do żądania ich usunięcia oraz prawo ograniczenia ich przetwarzania. Jeśli uznasz, że przetwarzamy Twoje dane osobowe niezgodnie z prawem, możesz wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.
            </p>

            <p className="form-small">Więcej informacji o ochronie Twoich danych osobowych oraz o zasadach wspólnego nimi administrowania przez BAT oraz CHIC znajdziesz w naszej Polityce Prywatności.</p>

            {this.state.consentError ? <p className="error-msg"><strong>Pažymėkite privalomus sutikimus</strong></p> : null}
            <div className="buttons">
              <Button className="btn white" action={ ()=>{ this.clear() }} >Anuluj</Button>
              <Button className="btn" action={ ()=>{ if (this.checkConsent()) this.props.mgmUpdate() }} >Potwierdź</Button>
            </div>
          </div>
        )
      case 'can-invite':
        return (
          <div className="mgm-modal-box">
            <h3>Podany użytkownik jest w bazie SalesForce</h3>
            <p>Czy potwierdzasz wysłanie zaproszenia?</p>
            <div className="buttons">
              <Button className="btn white" action={ ()=>{ this.clear() }} >Anuluj</Button>
              <Button className="btn" action={ ()=>{ this.props.mgmInvite() }} >Potwierdź</Button>
            </div>
          </div>
        )
      case 'not-found':
        return (
          <div className="mgm-modal-box">
            <h3>Przykro nam, nie ma takiego użytkownika</h3>
            <div className="center">
              <Button className="btn" action={ ()=>{ this.clear() }} >Zamknij</Button>
            </div>
          </div>
        )
      case 'finished':
        return (
          <div className="mgm-modal-box">
            <h3>Dziękujemy za potwierdzenie danych</h3>
            <p>SMS z linkiem do programu MGM zostanie wysłany na podany numer telefonu.</p>
            <div className="center">
              <Button className="btn" action={ ()=>{ this.clear() }} >Zamknij</Button>
            </div>
          </div>
        )
      case 'error':
        return (
          <div className="mgm-modal-box">
            <h3>Oi!</h3>
            <p>Kažkas nutiko.</p>
            <div className="center">
              <Button className="btn" action={ ()=>{ this.clear() }} >Zamknij</Button>
            </div>
          </div>
        )
      case 'processing':
        return (
          <div className="mgm-modal-box">
            <svg className="preloader" viewBox="0 0 90 90">
              <circle id="c" fill="none" stroke-width="4" stroke-linecap="round" stroke="red" cx="45" cy="45" r="43" />
            </svg>
          </div>
        )
      case 'already-member':
        return (
          <div className="mgm-modal-box">
            <h3>Użytkownik jest już w Strefie Wyboru. Nie możemy wysłać zaproszenia.</h3>
            <div className="center">
              <Button className="btn" action={ ()=>{ this.clear() }} >Zamknij</Button>
            </div>
          </div>
        )
      case 'no-products':
        return (
          <div className="mgm-modal-box">
            <h3>Użytkownik nie ma zarejestrowanych urządzeń. Nie możemy wysłać zaproszenia.</h3>
            <div className="center">
              <Button className="btn" action={ ()=>{ this.clear() }} >Zamknij</Button>
            </div>
          </div>
        )
      case 'no-consents':
        return (
          <div className="mgm-modal-box">
            <h3>Podany użytkownik nie wyraził wszystkich zgód marketingowych</h3>
            <p>Poproś użytkownika, by potwierdził wyrażenie zgód marketingowych.</p>
            <div className="buttons">
              <Button className="btn white" action={ ()=>{ this.clear() }} >Anuluj</Button>
              <Button className="btn" action={ ()=>{ this.props.mgmShowForm() }} >Potwierdź</Button>
            </div>
          </div>
        )
      default:
        return '';
    }
  }

  render() {

    return (
      <section className="mgm">
        <h2 className="title">MGM</h2>
        <div className="mgm-account">
          <div className="points">
            <div className="user-points">{ this.props.mgm.ranking?.balance }</div>
            <div>Twoje punkty</div>
          </div>
          <div className="ranking">
            <div className="user-placement">{ this.props.mgm.ranking?.position }</div>
            <div>Twoje miejsce w rankingu</div>
          </div>
        </div>
        { this.content() } 
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, mgm, actions } = state
  return { screen, mgm, actions }
};

export default withRouter(connect(
  mapStateToProps,
  {
    mgmJoin,
    mgmValidate,
    mgmClear,
    mgmShowForm,
    mgmInvite,
    mgmUpdate,
    setCommercialOptIn,
    setMobileOptIn,
    setHypercareOptIn,
    setSocialOptIn
  }
)(ReportsPage));