import React, { useEffect, useState } from 'react';

export const SuccessPage = ({ setSuccess, resetForm }) => {
	const [isMounted, setIsMounted] = useState(false);

	useEffect(()=>{
			setIsMounted(true);
	},[]);

	return (
		<div className="success-page">
			<div className="container">
				<h2 className={`first ${isMounted ? 'show' : ''}`}>
					<span>Registracija sėkminga, ačiū kad užsiregistravote</span>
				</h2>
				{/* <p className={`second center ${isMounted ? 'show' : ''}`}>
					<span>
						Od teraz możemy dzielić się z Tobą spersonalizowanymi informacjami o nowych ofertach
						specjalnych, a także ciekawostkami związanymi z Twoim urządzeniem.
					</span>
				</p>
				<h2 className={`third ${isMounted ? 'show' : ''}`}>
					<span>Do usłyszenia! </span>
				</h2> */}
				<button onClick={() => {setSuccess(false); resetForm()}} className={`btn small-btn empty-btn`}>
					Atgal
				</button>
			</div>
		</div>
	);
};
