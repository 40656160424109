import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import _ from 'lodash';
import BaseComponent from "../components/Helpers";
import Button from './Button';
import LocationView from './LocationView';
import { ReactComponent as Arrow } from '../../img/arrow-back.svg'
import moment from 'moment'
import Receipt from "../../img/receipt.jpg"


// const data = [
//   {
//     img: Receipt,
//     text: "Paragon #4",
//     time: "18:00"
//   },
//   {
//     img: Receipt,
//     text: "Paragon #3",
//     time: "17:34"
//   },
//   {
//     img: Receipt,
//     text: "Paragon #2",
//     time: "16:59"
//   },
//   {
//     img: Receipt,
//     text: "Paragon #1",
//     time: "16:30"
//   },
// ]

// const DEV_API = "https://api-dev.tourplanner.lt"
// const PROD_API = "https://api.tourplanner.lt"
// const CURRENT_API = PROD_API

const ENDPOINT_LIST = "/action/receipt/list"
const ENDPOINT_SERVE = "/action/receipt/serve"

const userData = JSON.parse(localStorage.getItem("state"))


class SingleReportPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      popup: false,
      showReceipts: false,
      receiptsArray: [],
      test: ""
    };
    this.reportID = this.props.match.params.id
    this.status = {
      "finished": "Atlikta",
      "accepted": "Patvirtinta"
    }
  }

  async loadReceipts() {
    const response = await fetch(process.env.REACT_APP_API_URL + ENDPOINT_LIST, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + userData.user.token.uuid
        // 'X-Mode-Local': sessionStorage.getItem('devParams') ? true : false,
      },
      body: JSON.stringify({
        "action": {
          "uuid": this.reportID
        },
        "pagination": {
          "limit": 100,
          "offset": 0
        }
      })
    })

    const data = await response.json()

    if (data.status.success) {
      const newReceiptsArray = [...data?.data?.["receipts"]]
      let counter = 0
      
      // newReceiptsArray.forEach(e => e.img=this.loadImage(e.uuid))

      for(const receipt of newReceiptsArray) {
        counter += 1
        receipt.img = await this.loadImage(receipt.uuid)
      }

      this.setState({
        showReceipts: true,
        receiptsArray: newReceiptsArray
      })
    }
  }

  async loadImage(uuid) {
    const response = await fetch(process.env.REACT_APP_API_URL + ENDPOINT_SERVE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + userData.user.token.uuid
      },
      body: JSON.stringify({
        "receipt": {
          "uuid": uuid
        }
      })
    })
    const blob = await response.blob()
    return URL.createObjectURL(blob)
  }

  render() {
    const report = _.filter(this.props.reports.list, { uuid: this.reportID })[0];

    return (
      <section className="report">
        <NavLink to="/raporty" className="back"><Arrow /></NavLink>
        <h1 className="title">{report.name}</h1>
        <p className="subtitle">{"Veiklos pavadinimas ID: " + report.ident}</p>
        <div className="report-details">
          <div className="report-detail">
            <div className="detail-name">Aktyvacijos data</div>
            <div className="detail-value">{moment(report.since.date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')}</div>
          </div>
          <div className="report-detail">
            <div className="detail-name">Teritorija</div>
            <div className="detail-value">{report.actionPoints[0].address.cityName}</div>
          </div>
          <div className="report-detail">
            <div className="detail-name">Produkto ambasadorius</div>
            <div className="detail-value">{this.props.user.user.firstname + " " + this.props.user.user.lastname + ", ID " + this.props.user.user.ident}</div>
          </div>
          <div className="report-detail">
            <div className="detail-name">Pradžios laikas</div>
            <div className="detail-value">{moment(report.since.date, 'YYYY-MM-DD HH:mm:ss').format("HH:mm:ss")}</div>
          </div>
          <div className="report-detail">
            <div className="detail-name">Pabaigos laikas</div>
            <div className="detail-value">{moment(report.until.date, 'YYYY-MM-DD HH:mm:ss').format("HH:mm:ss")}</div>
          </div>
          <div className="report-detail">
            <div className="detail-name">Vietos pakeitimo laikas</div>
            <div className="detail-value">{report.status.user.lastStatusCreated != null ? moment(report.status.user.lastStatusCreated.date).format("HH:mm:ss") : '-'}</div>
          </div>
          <div className="report-detail">
            <div className="detail-name">Aktyvacijos trukmė</div>
            <div className="detail-value">{this.leadingZero(moment(report.until.date).diff(moment(report.since.date), 'hours')) + ":" + this.leadingZero(moment(report.until.date).diff(moment(report.since.date), 'minutes') % 60) + ":" + this.leadingZero(moment(report.until.date).diff(moment(report.since.date), 'seconds') % 60)}</div>
          </div>
          <div className="report-detail">
            <div className="detail-name">Statusas</div>
            <div className="detail-value">{this.status[report.status.global.ident]}</div>
          </div>
        </div>
        <h3>Aktyvacijos vieta</h3>
        {report.actionPoints.map((item, key) => {
          return <LocationView key={key} data={item} />
        })}
        <h3>Kvitai</h3>
        {this.state.showReceipts ?
          <div className="receipts">
            {this.state.receiptsArray?.map((element, index) => (
              <div className="receipt" key={index}>
                <div className="image">
                  <img src={element.img} alt="" />
                </div>
                <div className="text">
                  <p>{element.device}</p>
                  {/* <p>{element.text + " - " + element.time}</p> */}
                </div>
              </div>
            ))}
            <img src={this.state.test} alt="" />
          </div>
          :
          <button className='btn' onClick={() => this.loadReceipts()}>Rodyti kvitus</button>
        }
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, reports, user } = state
  return { screen, reports, user }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(SingleReportPage));