import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { login, ssoLogin, redirectLogin } from '../actions/user';
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg'
import { useHistory } from "react-router-dom";
import Button from './Button'

function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    props.redirectLogin(props.location.search.substr(7,props.location.search.length), history)
  }, [])

  let validate = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Wpisz adres email")
    } else if (pass === "") {
      setError("Wpisz hasło")
    } else {
      setError("")
      login()
    }
  }

  let login = () => {
    let data = {
      username: email,
      password: pass
    }
    
    props.login(data, history)
  }

  return (
    <section className="login-page">
    	<Logo className="bat-logo"/>
    	<div className="page-inner">
  			<h2>Prisijungti</h2>
  			<p>Įvesti el.paštą ir slaptažodį</p>
  			<form>
          <div className="center">
          <div className="input-wrapper">
            <input type="email" placeholder="El.paštas" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
            </div>
          </div>
          <div className="center">
          <div className="input-wrapper">
            <input type="password" placeholder="Slaptažodis" value={ pass } onChange={ (e) => { setPass(e.target.value) } }/>
            </div>
          </div>
          <div className="remind">
            Pamiršote prisijungimo duomenis?  > <NavLink to="/remind">Slaptažodžio atstatymas.</NavLink>
          </div>
          { error !== "" ? <p className="error">{ error }</p> : null }
          <div className="center">
            <Button className="btn" action={ (e) => { validate(e) } }>Pirmyn</Button>
          </div>
          {false && <div className="center">
            <Button className="btn" action={ (e) => { props.ssoLogin() } }>SSO Login</Button>
          </div>}
        </form>
  		</div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    login,
    ssoLogin,
    redirectLogin
  }
)(LoginPage));