import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { uploadReports, getRecent } from '../actions/reports'
import BaseComponent from "../components/Helpers";
import { NavLink } from 'react-router-dom';
import _ from 'lodash'
import moment from 'moment';

class ReportsPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      open: {
        currentAction: {
          glo: false,
          vuse: false,
          velo: false
        },
        lastAction: {
          glo: false,
          vuse: false,
          velo: false
        },
        currentMonth: {
          glo: false,
          vuse: false,
          velo: false
        },
        lastMonth: {
          glo: false,
          vuse: false,
          velo: false
        }
      }
    };
  }

  componentWillMount() {
    this.props.uploadReports()
    this.props.getRecent()
  }

  getCount(stat, brand) {
    let count = 0
    for (let i = 0; i < _.filter(stat, { brand: brand }).length; i++) {
      const element = _.filter(stat, { brand: brand })[i];
      count += element.count
    }
    return count
  }

  toggleProducts(stat, product) {
    let open = this.state.open
    if(open[stat][product]) {
      open[stat][product] = false
    } else {
      open[stat][product] = true
    }
    this.setState({ open: open })
  }

  render() {
    const reports = _.filter(this.props.reports.list, (action) => {
      if (action.status.global.ident === "finished") {
        return action
      }
    })

    const currentAction = this.props.reports?.stats?.sample?.currentAction || []
    const currentActionCountGlo = this.getCount(currentAction, "Glo")
    const currentActionCountVuse = this.getCount(currentAction, "Vuse")
    const currentActionCountVelo = this.getCount(currentAction, "Velo")

    const lastAction = this.props.reports?.stats?.sample?.lastAction || []
    const lastActionCountGlo = this.getCount(lastAction, "Glo")
    const lastActionCountVuse = this.getCount(lastAction, "Vuse")
    const lastActionCountVelo = this.getCount(lastAction, "Velo")

    const currentMonth = this.props.reports?.stats?.sample?.currentMonth || []
    const currentMonthCountGlo = this.getCount(currentMonth, "Glo")
    const currentMonthCountVuse = this.getCount(currentMonth, "Vuse")
    const currentMonthCountVelo = this.getCount(currentMonth, "Velo")

    const lastMonth = this.props.reports?.stats?.sample?.lastMonth || []
    const lastMonthCountGlo = this.getCount(lastMonth, "Glo")
    const lastMonthCountVuse = this.getCount(lastMonth, "Vuse")
    const lastMonthCountVelo = this.getCount(lastMonth, "Velo")

    return (
      <section className="reports">
        <h2 className="title">Ataskaita</h2>
        <>
          <h3>Parduoto įrenginio numeris:</h3>
          <div className="reports-stats">
          { currentAction.length > 0 && <div>
              <h4>Dabartinė aktyvacija</h4>
              <ul className="top">
              { currentActionCountGlo > 0 &&<li className="stat-item">
                  <span className="name">glo hyper+ UNIQ</span>
                  {/* <span className={ this.state.open["currentAction"]["glo"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("currentAction", "glo") } }></span> */}
                  <span className="count">{ currentActionCountGlo }</span>
                  { this.state.open["currentAction"]["glo"] && <ul className="sub">
                    { _.filter(currentAction, { brand: "Glo" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> }
                {/* { currentActionCountVuse > 0 &&<li className="stat-item">
                  <span className="name">vuse</span>
                  <span className={ this.state.open["currentAction"]["vuse"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("currentAction", "vuse") } }></span>
                  <span className="count">{ currentActionCountVuse }</span>
                  { this.state.open["currentAction"]["vuse"] && <ul className="sub">
                    { _.filter(currentAction, { brand: "Vuse" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> } */}
                {/* { currentActionCountVelo > 0 &&<li className="stat-item">
                  <span className="name">velo</span>
                  <span className={ this.state.open["currentAction"]["velo"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("currentAction", "velo") } }></span>
                  <span className="count">{ currentActionCountVelo }</span>
                  { this.state.open["currentAction"]["velo"] && <ul className="sub">
                    { _.filter(currentAction, { brand: "Velo" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> } */}
              </ul>
            </div> }
            { lastAction.length > 0 && <div>
              <h4>Ostatnia zakończona akcja:</h4>
              <ul className="top">
                { lastActionCountGlo > 0 && <li className="stat-item">
                  <span className="name">glo hyper+ UNIQ</span>
                  {/* <span className={ this.state.open["lastAction"]["glo"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("lastAction", "glo") } }></span> */}
                  <span className="count">{ lastActionCountGlo }</span>
                  { this.state.open["lastAction"]["glo"] && <ul className="sub">
                    { _.filter(lastAction, { brand: "Glo" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> }
                {/* { lastActionCountVuse > 0 && <li className="stat-item">
                  <span className="name">vuse</span>
                  <span className={ this.state.open["lastAction"]["vuse"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("lastAction", "vuse") } }></span>
                  <span className="count">{ lastActionCountVuse }</span>
                  { this.state.open["lastAction"]["vuse"] && <ul className="sub">
                    { _.filter(lastAction, { brand: "Vuse" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> } */}
                {/* { lastActionCountVelo > 0 &&<li className="stat-item">
                  <span className="name">velo</span>
                  <span className={ this.state.open["lastAction"]["velo"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("lastAction", "velo") } }></span>
                  <span className="count">{ lastActionCountVelo }</span>
                  { this.state.open["lastAction"]["velo"] && <ul className="sub">
                    { _.filter(lastAction, { brand: "Velo" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> } */}
              </ul>
            </div> }
            { currentMonth.length > 0 && <div>
              <h4>Einamasis mėnuo</h4>
              <ul className="top">
                { currentMonthCountGlo > 0 && <li className="stat-item">
                  <span className="name">glo hyper+ UNIQ</span>
                  {/* <span className={ this.state.open["currentMonth"]["glo"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("currentMonth", "glo") } }></span> */}
                  <span className="count">{ currentMonthCountGlo }</span>
                  { this.state.open["currentMonth"]["glo"] && <ul className="sub">
                    { _.filter(currentMonth, { brand: "Glo" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> }
                {/* { currentMonthCountVuse > 0 && <li className="stat-item">
                  <span className="name">vuse</span>
                  <span className={ this.state.open["currentMonth"]["vuse"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("currentMonth", "vuse") } }></span>
                  <span className="count">{ currentMonthCountVuse }</span>
                  { this.state.open["currentMonth"]["vuse"] && <ul className="sub">
                    { _.filter(currentMonth, { brand: "Vuse" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> } */}
                {/* { currentMonthCountVelo > 0 && <li className="stat-item">
                  <span className="name">velo</span>
                  <span className={ this.state.open["currentMonth"]["velo"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("currentMonth", "velo") } }></span>
                  <span className="count">{ currentMonthCountVelo }</span>
                  { this.state.open["currentMonth"]["velo"] && <ul className="sub">
                    { _.filter(currentMonth, { brand: "Velo" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> } */}
              </ul>
            </div> }
            { lastMonth.length > 0 && <div>
              <h4>Praėjęs mėnuo</h4>
              <ul className="top">
                { lastMonthCountGlo > 0 && <li className="stat-item">
                  <span className="name">glo hyper+ UNIQ</span>
                  {/* <span className={ this.state.open["lastMonth"]["glo"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("lastMonth", "glo") } }></span> */}
                  <span className="count">{ lastMonthCountGlo }</span>
                  { this.state.open["lastMonth"]["glo"] && <ul className="sub">
                    { _.filter(lastMonth, { brand: "Glo" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> }
                {/* { lastMonthCountVuse > 0 && <li className="stat-item">
                  <span className="name">vuse</span>
                  <span className={ this.state.open["lastMonth"]["vuse"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("lastMonth", "vuse") } }></span>
                  <span className="count">{ lastMonthCountVuse }</span>
                  { this.state.open["lastMonth"]["vuse"] && <ul className="sub">
                    { _.filter(lastMonth, { brand: "Vuse" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> } */}
                {/* { lastMonthCountVelo > 0 && <li className="stat-item">
                  <span className="name">velo</span>
                  <span className={ this.state.open["lastMonth"]["velo"] ? "toggle open" : "toggle" } onClick={ () => { this.toggleProducts("lastMonth", "velo") } }></span>
                  <span className="count">{ lastMonthCountVelo }</span>
                  { this.state.open["lastMonth"]["velo"] && <ul className="sub">
                    { _.filter(lastMonth, { brand: "Velo" }).map((item, i) => {
                      return (
                        <li key={i}>
                          <span className="name">{ item.model }</span>
                          <span className="count">{ item.count }</span>
                        </li>
                      )
                    }) }
                  </ul> }
                </li> } */}
              </ul>
            </div> }
          </div>
        </>
        <h3>Aktyvacijų ataskaitos:</h3>
        { reports.length > 0 ? reports.map((item, key) => {
            return (
              <NavLink to={"/raport/"+item.uuid} key={ 'report-'+key } className={ "reports-item" }>
                <div className="report-name">{ item.name }</div>
                <div className="date">{ moment(item.since.date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY') }</div>
              </NavLink>
            )
          })
        : <h3 style={{ textAlign: "center" }}>Aktyvacija nėra uždarytą.</h3>}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, reports } = state
  return { screen, reports }
};

export default withRouter(connect(
  mapStateToProps,
  {
    uploadReports,
    getRecent
  }
)(ReportsPage));