import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../components/Helpers";
import { NavLink } from 'react-router-dom';
import Calendar from "../components/Calendar";

class ReportsPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { };
  }


  render() {

    return (
      <section className="calendar-page">
        <Calendar />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, reports } = state
  return { screen, reports }
};

export default withRouter(connect(
  mapStateToProps,
  {

  }
)(ReportsPage));