import React, { Component } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import BaseComponent from "../components/Helpers";
import Header from "../components/Header"
import { saveDimensionData } from '../actions/screen';
import { checkSession} from '../actions/user';
import { withRouter } from 'react-router-dom';
import Toasts from '../components/toasts/Toasts';
import Button from '../components/Button';
import DATA from '../data/data.json';
import { ReactComponent as ColorLine } from '../../img/color_line.svg'

class App extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { };
    //this._bind('checkSession');
  }

  componentWillMount() {
    //console.log('////////////////////////////////////// MOUNTING APP ////////////////////////////////////////')
    this.updateDimensions();
    this.props.checkSession(this.props.history)
  }

  componentDidMount() {
    //console.log('////////////////////////////////////// APP MOUNTED ////////////////////////////////////////')
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.props.saveDimensionData({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }

  render() {

    return (
      <div className="app">
        <ColorLine className="color-line" preserveAspectRatio="none"/>
        { this.props.location.pathname !== "/" && this.props.location.pathname !== "/remind" ? <Header location={ this.props.history.location }/> : null }
        {this.props.children}
        <Toasts />
        <div className="page-preloader"><span></span></div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { screen } = state
  return { screen }
};

export default withRouter(connect(
  mapStateToProps,
  {
    saveDimensionData,
    checkSession
  }
)(App));