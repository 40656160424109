import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink, withRouter } from 'react-router-dom';
import { startAction, stopAction } from '../actions/actions';
import { redeemVoucher, requestVouchers, getBatches, getVouchers, selectBatch } from '../actions/vouchers';
import { addToast } from '../actions/toast';
import _ from 'lodash';
import BaseComponent from "../components/Helpers";
import BottomModal from './BottomModal';
import Button from './Button';
import LocationView from './LocationView';
import PopupModal from './PopupModal';
import { ReactComponent as Arrow } from '../../img/arrow-back.svg';
import moment from 'moment';

class SingleActionsPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.actionID = this.props.match.params.id
    this.action = _.filter(this.props.actions.list, { uuid: this.actionID })[0];
    this.locationKey = _.findIndex(this.action.actionPoints, (o) => { return o.uuid == this.action.status.user.actionPoint.uuid; });
    this.state = { 
      codesPopup: false, 
      popup: false, 
      location: this.locationKey, 
      modal: 'codes', 
      selectedItem: 0, 
      selectedActionId: 0, 
      confirmPopup: false,
      batchesPopup: false,
      confirmStartPopup: false
    };
  }

  componentDidMount() {
    this.props.getBatches(this.action.event.uuid)
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if(this.action !== _.filter(nextProps.actions.list, { uuid: this.actionID })[0]) {
      this.action = _.filter(nextProps.actions.list, { uuid: this.actionID })[0];
      this.locationKey = _.findIndex(this.action.actionPoints, (o) => { return o.uuid == this.action.status.user.actionPoint.uuid; });
      this.setState({ location: this.locationKey })
    }
  }

  toggleAction(status) {
    if ( moment(this.action.since.date, 'YYYY-MM-DD').format('Y-M-D') === moment().format('Y-M-D') ) {
      this.setState({ confirmPopup: true })
    } else {
      this.props.addToast({ type: "error", message: "Jeszcze nie można rozpocząć tej akcji" });
    }
  }

  toggleActionProcess(status, id) {
    if (status) {
      const data = {
        "actionPoint": {
            "uuid": this.action.actionPoints[0] ? this.action.actionPoints[0].uuid : null
        }
      }

      this.props.stopAction(data);
    } else {
      const data = {
        "actionPoint": {
            "uuid": this.action.actionPoints[0] ? this.action.actionPoints[0].uuid : null
        }
      }

      this.props.startAction(data);
    }
  }

  openRedeemPopup(action, item) {
    if (!item.redeemed) {
      this.setState({selectedActionId: this.action.uuid, selectedItem: item, codesPopup: true })
    }
  }

  requestCodes() {
    const data = {
      "action": {
          "uuid": this.action.uuid
      }
    }

    this.props.requestCodes(data)
  }

  render() {
    let emptyVouchers = false;
    let availableVouchers = [];
    if (this.props.vouchers.vouchers && this.props.vouchers.vouchers.length > 0) {
      availableVouchers = this.props.vouchers.vouchers.filter((item, key)=>{
        return item.redeemed === null
      })
      if (availableVouchers.length <= 0) {
        emptyVouchers = true;
      }
    } else {
      emptyVouchers = true
    }

    let location = this.action.actionPoints[0];
    let actionStatus = this.action.status.user.status === "started" ? true : false
    return (
      <section className="action">
        <NavLink to="/akcje" className="back"><Arrow/></NavLink>
        <h1 className="title">{ this.action.name }</h1>
        <p style={{ textAlign: "center" }}>Aktyvacijos ID { this.action.ident }</p>
        { this.action.status.user.status === "started" ? 
          <div className="info center">
            Galite uždaryti savo aktyvaciją.
          </div> : null 
        }
        <Button className="btn" style={ this.props.actions.progress ? { opacity: "0.4" } : {} } action={ () => { if(!this.props.actions.progress) this.toggleAction(actionStatus, this.action.uuid) } }>{ this.action.status.user.status === "started" ? 'Pabaigti' : 'Pradėti'}</Button>
        <div className="action-info">
          <h3>Data</h3>
          <p>{ this.action.since != null && this.action.until != null ? <span className="date">{ moment(this.action.since.date, 'YYYY-MM-DD').format('D-M-Y') + ", " + moment(this.action.since.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') + ' - ' + moment(this.action.until.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') }</span> : '' }</p>
          <h3>Vieta</h3>
          { this.action.actionPoints.length > 0 ? 
            <>
              <h4>{ location.name }</h4>
              <p>{ location.address.streetAddress }{ location.address.postalCode != null ? ', ' + location.address.postalCode : '' }{ ', ' + location.address.cityName }</p>
            </>
          : ''}
        </div>
        {/* <Button 
          type={this.action.status.user.status === "started" ? "orange" : "inactive"} 
          action={ 
            ()=>{ 
              if (this.action.status.user.status === "started") {
                this.setState({ batchesPopup: true, }) 
              }
            } 
          }>
            Kupono kodas</Button> */}
        <BottomModal open={ this.state.popup } hidePopup={ ()=>{ this.setState({ popup: false }) } }>
          { this.state.modal === 'codes' ?
            <div className="popup-inner popup-codes">
              { emptyVouchers ?
                <div className="no-vouchers">
                  <h3>Visi kodai iš šios grupės jau yra panaudoti. </h3>
                  <button className="btn" onClick={ () => { this.props.requestVouchers() } }>Atsisiųsk naujus kodus</button>
                </div> : null }
                { availableVouchers.map((item, key) => {
                  const cl = item.redeemed ? 'active' : '';
                    return (
                      <div key={ key } className={ "code-item "+cl }>
                        <span className="nr">{ (key+1)+'.' }</span>
                        <div className="cupon-code">{ item.ident }</div>
                        <Button type="redeem" action={ ()=>{ this.openRedeemPopup(this.action, item) }}>Wydaj</Button>
                      </div>
                    )
                  })
                }
            </div> :
            <div className="popup-inner">
              { this.action.actionPoints.map((item, key) => {
                  return (
                    <div key={ 'location-'+key } onClick={ ()=>{ this.setState({ location: key, popup: false })} }><LocationView data={ item } /></div>
                  )
                })
              }
            </div>
          }
        </BottomModal>
        <PopupModal open={ this.state.codesPopup } hidePopup={ ()=>{ this.setState({ codesPopup: false }) } }>
          <div className="popup-inner">
            <h3>{ 'Naudoti kodą '+this.state.selectedItem.ident+':'}</h3>
            <div className="buttons">
              <Button type="empty" action={ ()=>{ this.setState({ codesPopup: false }); } }>Atšaukti</Button>
              <Button action={ ()=>{ this.setState({ codesPopup: false }); this.props.redeemVoucher(this.state.selectedItem.uuid); } }>Patvirtinti</Button>
            </div>
          </div>
        </PopupModal>
        <PopupModal open={ this.state.batchesPopup } hidePopup={ ()=>{ this.setState({ batchesPopup: false }) } }>
          <div className="popup-inner">
            <h3>{ 'Pasirinkti kodų grupę'}</h3>
            <div className="batches">
              { this.props.vouchers.batches ? this.props.vouchers.batches.map((item, key) => {
                  return (
                    <button key={'category-'+key} className="btn" onClick={ 
                      () => { 
                        this.props.selectBatch(item.uuid); 
                        this.setState({ popup: true, modal: 'codes', batchesPopup: false }) 
                      } 
                    }>{ item.ident }</button>
                  )
                }) : null
              }
            </div>
          </div>
        </PopupModal>
        <PopupModal open={ this.state.confirmPopup } hidePopup={ ()=>{ this.setState({ confirmPopup: false }) } }>
          <div className="popup-inner">
            <h3>{ actionStatus ? "Uždaryti aktyvaciją?" : "Rozpocząć akcję?" }</h3>
            <div className="buttons">
              <Button type="empty" action={ ()=>{ this.setState({ confirmPopup: false }); } }>Atšaukti</Button>
              <Button style={ this.props.actions.progress ? { opacity: "0.4" } : {} } action={ ()=>{ this.setState({ confirmPopup: false }); if(!this.props.actions.progress) this.toggleActionProcess(actionStatus) } }>Patvirtinti</Button>
            </div>
          </div>
        </PopupModal>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, actions, vouchers } = state
  return { screen, actions, vouchers }
};

export default withRouter(connect(
  mapStateToProps,
  {
    startAction, 
    stopAction,
    addToast,
    getBatches,
    selectBatch,
    requestVouchers,
    redeemVoucher
  }
)(SingleActionsPage));