import {
    LOGIN,
    LOGOUT,
    REMIND,
    SESSION
  } from "../actions/types";

  function hidePreloader() {
    const preloader = document.querySelector("div.page-preloader")
    setTimeout(() => {
      preloader.classList.add("hide")
    }, 600)
  }
  
  export default function(state = {}, action) {
    switch (action.type) {
      case LOGIN:
        return {
          ...state,
          token: action.payload.token,
          user: action.payload.user
        }
      case SESSION:
          hidePreloader()
          return {
            ...state,
            token: action.payload.token,
            user: action.payload.user
          }
      case LOGOUT:
          return {
              ...state,
              token: null,
              user: null
          }
      case REMIND:
          return {
              ...state,
              list: action.payload,
          }

      default:
        return state;
    }
  }

export const getUserID = (state) => state.user?.user?.ident;
export const getUser = (state) => state.user?.user;