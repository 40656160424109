import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import BaseComponent from "../components/Helpers";
import { startAction, stopAction } from '../actions/actions';
import { mgmClear } from '../actions/mgm';
import { NavLink } from 'react-router-dom';
import Button from './Button';
import PopupModal from './PopupModal';
import moment from 'moment';
import { addToast } from '../actions/toast';

class ActionsPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { confirmPopup: false, actionID: -1, actionStatus: null }
  }

  componentDidMount() {
    this.props.mgmClear();
  }

  toggleAction(status, id, action) {
    if ( moment(action.since.date).format('Y-M-D') === moment().format('Y-M-D') ) {
      this.setState({ confirmPopup: true, actionID: id, actionStatus: status })
    } else {
      this.props.addToast({ type: "error", message: "Šiuo metu negalite naudotis" });
    }
  }

  toggleActionProcess(status, id) {
    if (status) {
      const data = {
        "actionPoint": {
            "uuid": id
        }
      }

      this.props.stopAction(data);
    } else {
      const data = {
        "actionPoint": {
            "uuid": id
        }
      }

      this.props.startAction(data);
    }
  }

  render() {

    let filteredActions = [];

    if (this.props.actions.list) {
      filteredActions = this.props.actions.list;
      /*filteredActions = this.props.actions.list.filter((item, key) => {
        let today = moment().startOf('day');
        if (item.since) {
          return moment(item.since.date, 'YYYY-MM-DD HH:mm:ss').isSameOrAfter(today);
        } else {
          return false;
        }
      })*/
    }

    return (
      <section className="actions">
        <h2 className="title">Šiuo metu vykstančios aktyvacijos</h2>
        { filteredActions.map((item, key) => {
            const cl = item.status.user.status === "started" ? 'active' : "";
            
              return (
                <div key={ 'action-'+key } className={ "action-item "+cl }>
                  <NavLink to={"/akcja/"+item.uuid} className="action-name">
                  { item.since != null && item.until != null ? <span className="date">{ moment(item.since.date, 'YYYY-MM-DD').format('D-M-Y') + ", " + moment(item.since.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') + ' - ' + moment(item.until.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') }</span> : '' }
                    { item.name }
                  </NavLink>
                  { item.actionPoints.length === 1 || item.status.user.status === "started" ? <div className="start-stop" style={ this.props.actions.progress ? { opacity: "0.4" } : {} } onClick={ () => { if(!this.props.actions.progress) this.toggleAction(item.status.user.status === "started" ? true : false, item.actionPoints[0].uuid, item) } }></div> : null }
                </div>
              )
            
          })
        }
        <PopupModal open={ this.state.confirmPopup } hidePopup={ ()=>{ this.setState({ confirmPopup: false }) } }>
          <div className="popup-inner">
            <h3>{ this.state.actionStatus ? "Uždaryti aktyvaciją?" : "Pradėti" }</h3>
            <div className="buttons">
              <Button type="empty" action={ ()=>{ this.setState({ confirmPopup: false }); } }>Atšaukti</Button>
              <Button style={ this.props.actions.progress ? { opacity: "0.4" } : {} } action={ ()=>{ this.setState({ confirmPopup: false }); if(!this.props.actions.progress) this.toggleActionProcess(this.state.actionStatus, this.state.actionID) } }>Patvirtinti</Button>
            </div>
          </div>
        </PopupModal>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, actions } = state
  return { screen, actions }
};

export default withRouter(connect(
  mapStateToProps,
  {
    startAction,
    stopAction,
    addToast,
    mgmClear
  }
)(ActionsPage));