import React, { useEffect, useReducer, useRef, useState } from 'react';
import useOutsideAlerter from '../Hooks/useOutsideAlerter';

export const Input = ({
	name,
	label,
	type,
	placeholder,
	register,
	errors,
	phoneNumberPrefix,
	setValue,
	clearErrors,
	getValues,
	event,
	value,
	onChange = () => { },
	options,
	disabled
}) => {
	const [isChecked, setIsChecked] = useState(false);
	const [sexValue, setSexValue] = useState('');
	const [isDropdownOpened, setIsDropdownOpened] = useState(false);

	const Error = () => {
		if (errors !== undefined) {
			if (name in errors) {
				return (
					<div className="error">
						{errors[name].message !== ''
							? errors[name].message
							: errors[name].type === 'required'
								? type == 'checkbox'
									? ''
									: 'Šį lauką užpildyti būtina'
								: 'Neteisinga vertė'}
					</div>
				);
			} else return null;
		} else return null;
	};

	const Dropdown = ({ isOpened, setIsOpened, setValue, clearErrors, options }) => {
		const dropdownRef = useRef();

		useOutsideAlerter(dropdownRef, () => setIsOpened(false));

		if (options.length === 1) {
			setValue(name, options[0].value)
		}

		return (
			<div ref={dropdownRef} className={`dropdown ${isOpened ? '' : 'hide'}`}>
				{
					options?.map((item, key) => {
						return (
							<div
								onClick={() => {
									setValue(name, item.value);
									clearErrors(name);
									setIsOpened(false);
									onChange(item.value)
								}}
								className="option"
								key={key}
							>
								{item.name}
							</div>
						)
					})
				}
				{/*<div
					onClick={() => {
						setValue('deviceType', 'Glo');
						clearErrors('deviceType');
						setIsOpened(false);
						event("Glo")
					}}
					className="option"
				>
					glo™
				</div>
				<div
					onClick={() => {
						setValue('deviceType', 'Vuse');
						clearErrors('deviceType');
						setIsOpened(false);
						event("Vuse")
					}}
					className="option"
				>
					Vuse ePen
				</div>*/}
				{/* <div
					onClick={() => {
						setValue('deviceType', 'Velo');
						clearErrors('deviceType');
						setIsOpened(false);
						event("Velo")
					}}
					className="option"
				>
					Velo
				</div> */}
			</div>
		);
	};

	const Tooltip = () => {
		const tooltipRef = useRef();
		const [isOpen, setIsOpen] = useState(false);

		useOutsideAlerter(tooltipRef, () => setIsOpen(false));

		return (
			<div className="tooltip">
				<button className="btn-tooltip" type="button" onClick={() => setIsOpen(!isOpen)}>
					i
				</button>
				<div ref={tooltipRef} className={`info-box ${isOpen ? '' : 'hide'}`}>
					Numer urządzenia jest umieszczony z tyłu opakowania, oznaczony jako my <strong>glo™</strong>number
					dla urządzeń <strong>glo™</strong> HYPER i my Vuse number dla urządzeń Vuse ePen
				</div>
			</div>
		);
	};

	if (type !== 'checkbox' && type !== 'select') {
		return (
			<>
				<div
					className={`input-wrapper ${phoneNumberPrefix ? 'phone-prefix' : ''} ${name === 'sex' ? 'dropdown-input' : ''
						}`}
				>
					{phoneNumberPrefix ? <span className="phone-prefix">+48</span> : null}

					{label === 'Numer urządzenia' ? (
						<label htmlFor={name}>
							{label}
							<Tooltip />
						</label>
					) : (
						<label htmlFor={name} dangerouslySetInnerHTML={{ __html: label }}></label>
					)}

					<input
						onClick={name === 'sex' ? () => setIsDropdownOpened(!isDropdownOpened) : null}
						ref={register}
						name={name}
						placeholder={placeholder}
						type={type ? type : 'text'}
						onFocus={() => { event() }}
						value={value}
						onChange={onChange}
					/>
				</div>
				<Error />
			</>
		);
	} else if (type === 'select') {
		return (
			<>
				<div
					className={`input-wrapper ${phoneNumberPrefix ? 'phone-prefix' : ''} ${true ? 'dropdown-input' : ''
						} ${options.length === 1 ? "disable-pointer-events" : ""}`}
				>
					<>
						<div
							className={`input ${getValues(name) || value ? '' : 'empty'}`} style={disabled ? { pointerEvents: "none" } : {}}
							onClick={true ? () => { setIsDropdownOpened(!isDropdownOpened); event() } : null}
						>
							{value ? value : getValues(name) ? options.find(o => o.value === getValues(name))?.name : placeholder}
						</div>
						<input
							className="invisible"
							ref={register}
							name={name}
							placeholder={placeholder}
							type={type ? type : 'text'}
						/>
					</>
					<Dropdown
						setIsOpened={setIsDropdownOpened}
						setValue={setValue}
						clearErrors={clearErrors}
						isOpened={isDropdownOpened}
						options={options}
					/>
				</div>
				<Error />
			</>
		);
	} else
		return (
			<>
				<div
					className={`checkbox-wrapper ${errors && name in errors ? (errors[name].type === 'required' ? 'required' : '') : ''
						}`}
				>
					<input
						id={name}
						className="checkbox"
						name={name}
						type={type}
						value={isChecked}
						ref={register}
						onClick={() => {
							event();
							setIsChecked(!isChecked)
						}}
					/>
					<label htmlFor={name} dangerouslySetInnerHTML={{ __html: label }}></label>
				</div>
				<Error />
			</>
		);
};
