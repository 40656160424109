import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { remind, remindCode } from '../actions/user';
import { ReactComponent as Logo } from '../../img/logo-bat-blue.svg'

function RemindPage(props) {
  const history = useHistory();
  const { pathname } = useLocation()
  const [email, setEmail] = useState("");
  const [passWd, setPassWd] = useState("");
  const [error, setError] = useState("");
  const [sms, setSms] = useState("");
  const [smsSent, setSmsSent] = useState(false);

  let validate = (e) => {
    e.preventDefault()

    if (sms === "") {
      setError("Wpisz kod SMS")
    } else if (passWd === "") {
      setError("Wpisz hasło")
    } else {
      setError("")
      login()
    }
  }

  let validateSms = (e) => {
    e.preventDefault()

    if (email === "") {
      setError("Wpisz adres email")
    } else {
      setError("")
      sendSms()
    }
  }

  let sendSms = () => {
    let data = {
      username: email
    }

    sessionStorage.setItem('userName', email)
    props.remind(data, history)
  }

  let login = () => {
    let data = {
      username: sessionStorage.getItem('userName'),
      password: passWd,
      otp: sms
    }

    props.remindCode(data, history)
    sessionStorage.removeItem('userName')
  }

  return (
    <section className="remind-page">
    	<Logo className="bat-logo"/>
    	<div className="page-inner">
			<h2>Slaptažodžio atstatymas</h2>
			<p>Įveskite prisijungimo vardą</p>
			{ pathname === "/remind" ? <form>
		        <div className="center">
		          <input type="email" placeholder="prisijungimo vardas" value={ email } onChange={ (e) => { setEmail(e.target.value) } }/>
		        </div>
		        { error !== "" ? <p className="error">{ error }</p> : null }
		        <div className="center">
		          <button className="btn" onClick={ (e) => { validateSms(e) } }>Pirmyn</button>
		        </div>
		      </form> : <form>
		        <div className="center">
		          <input type="text" placeholder="Laikinas slaptažodis" value={ sms } onChange={ (e) => { setSms(e.target.value) } }/>
		        </div>
		        <div className="center">
		          <input type="password" placeholder="naujas slaptažodis" value={ passWd } onChange={ (e) => { setPassWd(e.target.value) } }/>
		        </div>
		        { error !== "" ? <p className="error">{ error }</p> : null }
		        <div className="center">
		          <button className="btn" onClick={ (e) => { validate(e) } }>Pirmyn</button>
		        </div>
	      </form> }
		</div>
    </section>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    remind, 
    remindCode
  }
)(RemindPage));