import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { logout } from '../actions/user'
// import { ReactComponent as Avatar } from '../../img/avatar.svg'
import { ReactComponent as ActionIcon } from '../../img/akcje.svg'
import { ReactComponent as CalendarIcon } from '../../img/calendar-icon.svg'
import { ReactComponent as ReportIcon } from '../../img/raporty.svg'
import { ReactComponent as AccountIcon } from '../../img/twoje-konto.svg'
import { ReactComponent as LogoutIcon } from '../../img/wyloguj.svg'

function Header(props) {
  const [nav, setNav] = useState(false)
  const { pathname } = useLocation();
  const history = useHistory()

  const toggleNav = () => {
    if (nav) {
      setNav(false)
    } else {
      setNav(true)
    }
  }

  return (
    <header>
      <div className="burger" onClick={ () => { toggleNav() } }><span></span></div>
      <nav className={ nav ? "open" : "" }>
        <div className="close" onClick={ () => { toggleNav() } }></div>
        <div className="nav-top">
          {/* <Avatar/> */}
          <h3><span>Labas</span><br/>{ props.user.user ? props.user.user.firstname : "" }</h3>
        </div>
        <div className="separator"></div>
        <ul>
          <li>
            <NavLink to="/akcje" onClick={ () => { setNav(false) } }>Aktyvacijos<ActionIcon/></NavLink>
          </li>
          <li>
            <NavLink to="/kalendarz" onClick={ () => { setNav(false) } }>Grafikas<CalendarIcon/></NavLink>
          </li>
          <li>
            <NavLink to="/raporty" onClick={ () => { setNav(false) } }>Ataskaitos<ReportIcon/></NavLink>
          </li>
          {/*<li>
            <NavLink to="/mgm" onClick={ () => { setNav(false) } }>Strefa Wyboru<ReportIcon/></NavLink>
          </li>*/}
          <li>
            <NavLink to="/webform" onClick={ () => { setNav(false) } }>Webform<ReportIcon/></NavLink>
          </li>
        </ul>
        <div className="separator" style={{ marginTop: "60px" }}></div>
        <ul>
          <li>
            <NavLink to="/user-data" onClick={ () => { setNav(false) } }>Vartotojo duomenys<AccountIcon/></NavLink>
          </li>
          <li>
            <span onClick={ () => { setNav(false); props.logout(history) } }>Išjungti<LogoutIcon/></span>
          </li>
        </ul>
      </nav>
    </header>
  );
}

const mapStateToProps = (state) => {
  const { screen, user } = state
  return { screen, user }
};

export default withRouter(connect(
  mapStateToProps,
  {
    logout
  }
)(Header));