import LoginPage from '../components/LoginPage';
import ActionsPage from '../components/ActionsPage';
import SingleActionPage from '../components/SingleActionPage';
import ReportsPage from '../components/ReportsPage';
import CalendarPage from '../components/CalendarPage';
import SingleReportPage from '../components/SingleReportPage';
import RemindPage from '../components/RemindPage';
import UserDataPage from '../components/UserDataPage';
import MGMPage from '../components/MGMPage';
import Webform from '../components/Webform';

export const Routes = [
	{
		route: '/',
		component: LoginPage,
		title: 'Strona główna',
		hideFooter: true
	},
	{
		route: '/login',
		component: LoginPage,
		title: 'Logowanie',
		hideFooter: true
	},
	{
		route: '/akcje',
		component: ActionsPage,
		title: 'Akcje'
	},
	{
		route: '/akcja/:id',
		component: SingleActionPage,
		title: 'Akcja'
	},
	{
		route: '/remind',
		component: RemindPage,
		title: 'Przypominj hasło',
		hideFooter: true
	},
	{
		route: '/remind/code',
		component: RemindPage,
		title: 'Przypominj hasło',
		hideFooter: true
	},
	{
		route: '/raporty',
		component: ReportsPage,
		title: 'Raporty'
	},
	{
		route: '/raport/:id',
		component: SingleReportPage,
		title: 'Raport'
	},
	{
		route: '/kalendarz',
		component: CalendarPage,
		title: 'Kalendarz'
	},
	{
		route: '/user-data',
		component: UserDataPage,
		title: 'Twoje dane'
	},
	{
		route: '/mgm',
		component: MGMPage,
		title: 'MGM'
	},
	{
		route: '/webform',
		component: Webform,
		title: 'Webform'
	}
]
