import {
  UPLOAD_AVAILABILITY,
  UPLOAD_REPORTS,
  GET_RECENT,
  GET_STATS
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case UPLOAD_REPORTS:
      return {
        ...state,
        list: action.payload.actions,
      }

    case UPLOAD_AVAILABILITY:
      return {
        ...state,
        list: action.payload,
      }

    case GET_RECENT:
      return {
        ...state,
        recent: action.payload,
      }

    case GET_STATS:
      return {
        ...state,
        stats: action.payload,
      }

    default:
      return state;
  }
}