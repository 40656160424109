import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { uploadAvailability, createAvailability, updateAvailability, deleteAvailability } from '../actions/reports'
import BaseComponent from "../components/Helpers";
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pl';
import BottomModal from './BottomModal';
import Button from './Button';
import PopupModal from './PopupModal';
import _ from 'lodash'
import 'moment/locale/lt'  // without this line it didn't work
moment.locale('lt')


class CalendarPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      availability: [{start: '09:00', end: '18:00'}],
      hourStart: 9,
      hourEnd: 16,
      selectedColumn: 0,
      selectedAvailability: null,
      popup: false,
      updatePopup: false,
      selectedMonth: moment().format("Y-M"),
      dropdown: false
    };
    moment().locale('pl');
    this.hours = ['06:00','07:00','08:00','09:00','10:00','11:00','12:00','13:00','14:00','15:00','16:00','17:00','18:00','19:00','20:00','21:00','22:00']
    this._bind('setAvailability', 'openPopup')
  }

  componentWillMount() {
    this.props.uploadAvailability()
  }

  setAvailability() {
    const since = this.state.selectedColumn.set("hour", this.state.hourStart).format("YYYY-MM-DD HH:mm:ss")
    const until = this.state.selectedColumn.set("hour", this.state.hourEnd).format("YYYY-MM-DD HH:mm:ss")
    
    const data = {
      "availability": {
          "since": since,
          "until": until
      }
    }

    this.props.createAvailability(data)
  }

  updateAvailability() {
    const since = moment(this.state.selectedAvailability.since.date).set("hour", this.state.hourStart).format("YYYY-MM-DD HH:mm:ss")
    const until = moment(this.state.selectedAvailability.until.date).set("hour", this.state.hourEnd).format("YYYY-MM-DD HH:mm:ss")

    const data = {
      "availability": {
          "since": since,
          "until": until,
          "uuid": this.state.selectedAvailability.uuid
      }
    }

    this.props.updateAvailability(data)
  }

  deleteAvailability() {
    const data = {
      "availability": {
          "uuid": this.state.selectedAvailability.uuid
      }
    }

    this.props.deleteAvailability(data)
  }

  openPopup(hour, date) {
    this.setState({ 
      hourStart: moment('2020-10-10 '+hour).format('H'),
      hourEnd: moment('2020-10-10 '+hour).add(1,'hours').format('H'),
      selectedColumn: date,
      popup: true
    })
  }

  openUpdatePopup(avail) {
    this.setState({ 
      hourStart: moment(avail.since.date).format('H'),
      hourEnd: moment(avail.until.date).format('H'),
      selectedAvailability: avail,
      updatePopup: true
    })
  }

  render() {
    const currentDay = moment().format("D")
    const currentMonth = moment().format("Y-M")
    const currentMonthDays = moment().daysInMonth()
    const selectedMonthDays = this.state.selectedMonth !== currentMonth ? moment(this.state.selectedMonth+'-1').daysInMonth() : currentMonthDays
    let days = []
    let columns = []

    for (let i = currentMonth !== this.state.selectedMonth ? 1 : currentDay; i <= selectedMonthDays; i++) {
      const day = moment( this.state.selectedMonth + "-" + i, 'Y-M-D')

      const item = (
        <div key={ i } className={ i === currentDay ? "calendar-day today" : "calendar-day" }>
          <div className="day-name">{ day.format("dd") }</div><div className="day-no">{ day.format("D")  }</div>
        </div>
      )
      days.push(item)
    }

    for (let i = currentMonth !== this.state.selectedMonth ? 1 : currentDay; i <= selectedMonthDays; i++) {
      const day = moment( this.state.selectedMonth + "-" + i, 'Y-M-D')
      const availabilityItems = _.filter(this.props.reports.list, (item) => {
        if(item.since != null && moment(item.since.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")) {
          return item
        }
      })

      const actionItems = _.filter(this.props.actions.list, (item) => {
        if(item.since != null && moment(item.since.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD")) {
          return item
        }
      })

      const item = (
        <div key={ i } className="calendar-column">
          { this.hours.map((item,key)=>{
            return <div key={ 'cell-'+key } className="hour-cell" 
              onClick={ ()=>{ this.openPopup(item, day) } }></div>
          })}
          { availabilityItems.map((avail, k) => {
            const diff = moment(avail.since.date).diff(moment(avail.until.date), 'hours');
            return (
              <div className="availability" 
                style={{ height: (-diff)*40-3, top: (((moment(avail.since.date).format("H")-6)*40)-3) + "px" }}
                onClick={ ()=>{ this.openUpdatePopup(avail) } }>
                <span>Užimtumas</span>
              </div>
            )
          }) }
          { actionItems.map((action, k) => {
            const diff = moment(action.since.date).diff(moment(action.until.date), 'hours');
            return <div className="event" style={{ height: (-diff)*40-3, top: (((moment(action.since.date).format("H")-6)*40)-3) + "px" }}><span>{ action.name }</span></div>
          }) }
        </div>
      )
      columns.push(item)
    }

    return (
      <section className="calendar">
        <div className="calendar-top">
          <div className="month">
            <p className="selected" onClick={ () => {
              this.setState({ dropdown: !this.state.dropdown })
            } }>{ moment(this.state.selectedMonth+'-1', 'YYYY-M-D').format("MMMM") }</p>
            <ul className={ this.state.dropdown ? "month-dropdown open" : "month-dropdown" }>
              <li onClick={ () => {
                this.setState({ dropdown: false, selectedMonth: moment().format("Y-M") })
              } }>{ moment().format("MMMM") }</li>
              <li onClick={ () => {
                this.setState({ dropdown: false, selectedMonth: moment().add(1, 'months').format("Y-M") })
              } }>{ moment().add(1, 'months').format("MMMM") }</li>
              <li onClick={ () => {
                this.setState({ dropdown: false, selectedMonth: moment().add(2, 'months').format("Y-M") })
              } }>{ moment().add(2, 'months').format("MMMM") }</li>
            </ul>
          </div>
        </div>
          <div className="calendar-hour-labels">
            { this.hours.map((item,key)=>{
              return <div key={ key } className="hour-label">{ item }</div>
            })}
          </div>
          <div className="calendar-columns">
            <div className="calendar-header">
              { days }
            </div>
            <div className="calendar-columns-inner">
              { columns }
            </div>
        </div>
        <PopupModal open={ this.state.popup } hidePopup={ ()=>{ this.setState({ popup: false }) } }>
          <div className="popup-inner">
            <h3>Įveskite aktyvacijos laiką:</h3>
            <div className="hours-inputs">
              <label>nuo</label>
              <input type="number" min="6" max="22" onChange={ (e)=>{ this.setState({ hourStart: e.target.value }); } } value={ this.state.hourStart} />
              <label>iki</label>
              <input type="number" min="6" max="22" onChange={ (e)=>{ this.setState({ hourEnd: e.target.value }) } } value={ this.state.hourEnd} />
            </div>
            <Button action={ ()=>{ this.setState({ popup: false }); this.setAvailability(); } }>Pirmyn</Button>
          </div>
        </PopupModal>

        <PopupModal open={ this.state.updatePopup } hidePopup={ ()=>{ this.setState({ popup: false }) } }>
          <div className="popup-inner">
            <h3>Įveskite savo pasiekiamumą</h3>
            <div className="hours-inputs">
              <label>nuo</label>
              <input type="number" min="6" max="22" onChange={ (e)=>{ this.setState({ hourStart: e.target.value }); } } value={ this.state.hourStart} />
              <label>iki</label>
              <input type="number" min="6" max="22" onChange={ (e)=>{ this.setState({ hourEnd: e.target.value }) } } value={ this.state.hourEnd} />
            </div>
            <Button action={ ()=>{ this.setState({ updatePopup: false }); this.updateAvailability(); } }>Išsaugoti</Button>
            <Button type="close" action={ ()=>{ this.setState({ updatePopup: false }); this.deleteAvailability(); } }>Ištrinti</Button>
          </div>
        </PopupModal>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, reports, actions } = state
  return { screen, reports, actions }
};

export default withRouter(connect(
  mapStateToProps,
  {
    uploadAvailability,
    createAvailability,
    updateAvailability,
    deleteAvailability
  }
)(CalendarPage));