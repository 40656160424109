import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { Route, Switch } from 'react-router' // react-router v4
import { BrowserRouter } from 'react-router-dom'
import configureStore, { history } from './app/store'
import { Routes } from './app/routes';
import { loadState } from './app/localStorage';
//import * as serviceWorker from './serviceWorker';
import App from './app/containers/App';
import Page from './app/containers/Page';
import data from './app/data/data.json';
import './scss/style.scss';

//serviceWorker.unregister();

const preloadedState = loadState();
const store = configureStore(preloadedState)

const routes = Routes.map((item, i) => {
  return (
    <Route key={ item.route+"-route" } 
      path={ item.route } exact 
      render={
        (props) => (
        	<Page>
	          <item.component
	            title={ item.title }
	            hideHeader={ item.hideHeader }
	            hideFooter={ item.hideFooter } {...props}
              data={ data } />
          </Page>
        )
      }/>
  )
})

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App>
        <Switch>
          { routes }
        </Switch>
      </App>
    </BrowserRouter>
  </Provider>, 
  document.getElementById('root'));